// faq_controller.js
import { Controller } from "stimulus";
import axios from 'axios'

const API_URL = location.host === 'localhost:9000' ? 'http://api.sakura_family.test' : 'https://api.sakura-family.com'
const TOKEN = location.host === 'localhost:9000' ? '77c5dc291ecd0a058906d07eda2d53d6f0176299cdbaf0f926264e5868956758' : 'aa4a281266187d599679b692521c81162ff6199c3ff70d01495b11e0811294fc'

export default class extends Controller {
  static targets = [ "code", "warning", "checkButton", "wrap" ]

  check() {
    const codeTarget = this.codeTarget;
    const warningTarget = this.warningTarget;
    const checkButtonTarget = this.checkButtonTarget;
    const wrapTarget = this.wrapTarget;
    const code = codeTarget.value.replace(/[^0-9]/g, '');
    checkButtonTarget.disabled = true;
    checkButtonTarget.innerHTML = '<div class="button-title">確認中</div>';

    axios.get(API_URL + '/api/v1/shops/check_zipcode', {
      headers: {
        "Authorization": `Bearer ${TOKEN}`
      },
      params: {
        "code": code,
      }
    })
    .then(function (response) {
      if (response.data.length > 0) {
        const event = new CustomEvent('checkZipcode', { detail: code })
        window.dispatchEvent(event);
        wrapTarget.classList.add('is-hidden');
      } else {
        const event = new CustomEvent('checkZipcode', { detail: false })
        window.dispatchEvent(event);
        warningTarget.classList.remove('is-hidden')
      }
    })
    .catch(function (reason) {
      warningTarget.classList.remove('is-hidden');
    })
    .finally(v=>{
      checkButtonTarget.disabled = false;
      checkButtonTarget.innerHTML = '<div class="button-title">対象エリアを確認</div>';
    });
  }

}
