// toggle_controller.js
import { Controller } from "stimulus";
import axios from 'axios'

export default class extends Controller {
  static targets = [ "answer", "arrow" ]

  toggleAnswer(event) {
    const arrowTarget = this.arrowTarget;
    if (arrowTarget.src && arrowTarget.src.match(/icon_arrow_under/)) {
      arrowTarget.setAttribute('src', 'assets/img/icon_arrow_top.png');
    } else {
      arrowTarget.setAttribute('src', 'assets/img/icon_arrow_under.png');
    }

    this.answerTarget.classList.toggle("active");
  }
}
