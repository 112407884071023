import { Controller } from "stimulus"
import moment from "moment"
import axios from 'axios'
const DAYS_RANGE = 7
const INTERVIEW_MINUTES = 120
const METHODS = [
  { id: 1, name: '店舗相談' },
  { id: 2, name: '訪問相談' },
  { id: 3, name: 'オンライン相談' }
]
const CONTACT_METHODS = [
  '電話', 'メール', 'SMS'
]

const MEDIA = [
  'ホームページ', '知人の紹介', 'ネット検索', '店頭・看板',
  '口コミ', '広告', 'SNS', '雑誌・新聞広告'
]

const CONSULTATION_CONTENT = [
  '保険の相談', '保険の見直し'
]

const API_URL = location.host === 'localhost:9000' ? 'http://api.sakura_family.test' : 'https://api.sakura-family.com'
const TOKEN = location.host === 'localhost:9000' ? '77c5dc291ecd0a058906d07eda2d53d6f0176299cdbaf0f926264e5868956758' : 'aa4a281266187d599679b692521c81162ff6199c3ff70d01495b11e0811294fc'

// momentjsの日本語設定
moment.locale("ja", {
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

// PCブラウザかどうか
const mediaQuery = window.matchMedia('(min-width: 770px)')

export default class extends Controller {
  static targets = [
    "calendarView", "prevWeekButton", "monthTitle",
    "modal", "interviewDate", "interviewMethod",
    "formText", "help", "formCheckBox", "contactMethod",
    "backButton", "sendButton", "confirmButton",
    "media", "formSelect", "consultationContent",
    "stepImage", "homeOnly"
  ]

  initialize() {
    this.targetDate = moment();
    this.methodId = Number(this.element.dataset.methodId);
    this.renderCalendarView(this.targetDate);
    this.setFormValues();
  }

  connect() {
    this.updatePrevWeekButton(this.targetDate);
  }

  /**
   * stateから入力フォームに値をセットする
   */
  setFormValues() {
    // text input
    this.formTextTargets.forEach((target, index) => {
      if (this[target.dataset.inputName]) {
        target.value = this[target.dataset.inputName];
      }
    });

    // 連絡方法
    this.contactMethodTargets.forEach((target, index) => {
      if (this.contactMethodIds && this.contactMethodIds.includes(index + 1)) {
        target.checked = true;
      }
    });

    // どうやって知ったか
    this.mediaTargets.forEach((target, index) => {
      if (this.mediaIds && this.mediaIds.includes(index + 1)) {
        target.checked = true;
      }
    });

    if (this.consultationContent) {
      this.consultationContentTarget.value = this.consultationContent;
    }
  }

  /**
   * ローディング開始
   */
  startLoading() {
    this.calendarViewTarget.innerHTML = `
      <div class="calendar-loading">
        <i class="fas fa-spinner fa-pulse"></i>
      </div>
    `;
    this.calendarViewTarget.classList.add('disabled');
  }

  /**
   * ローディング終了
   */
  endLoading() {
    this.calendarViewTarget.classList.remove('disabled');
  }

  /**
   * 「前の1週間」ボタンの制御
   */
  updatePrevWeekButton(targetDate) {
    if (targetDate.isSame(moment(), 'day')) {
      this.prevWeekButtonTargets.forEach((prevWeekButton) => {
        prevWeekButton.classList.add('disabled');
        prevWeekButton.disabled = true;
      });
    } else {
      this.prevWeekButtonTargets.forEach((prevWeekButton) => {
        prevWeekButton.classList.remove('disabled');
        prevWeekButton.disabled = false;
      });
    }
  }

  /**
   * カレンダー部分の描画
   */
  renderCalendarView(targetDate) {
    // 訪問相談の場合以外は住所入力なし
    if (this.methodId !== 2) {
      this.homeOnlyTargets.forEach((target, index) => {
        target.classList.add('is-hidden')
      });
    }
    // ローディング開始
    this.startLoading();
    const calendarView = this.calendarViewTarget;
    const startAtMonth = targetDate.format('MM');
    const endAtMonth = targetDate.clone().add(6, 'days').format('MM');
    // 月末を挟むかどうか
    const hasEndOfMonth = startAtMonth !== endAtMonth
    const monthTitle = hasEndOfMonth ?  `${startAtMonth}-${endAtMonth}` : startAtMonth;
    const yearTitle = targetDate.format('YYYY');
    this.monthTitleTarget.innerHTML = `${monthTitle} / <span>${yearTitle}</span>`

    // APIから取得するデータの期間開始日
    const startAt = targetDate.unix();
    // APIから取得するデータの期間終了日
    const endAt = targetDate.clone().add(6, 'days').unix();
    // get schedules APIを叩く
    axios.get(API_URL + '/api/v1/shops/schedules', {
      headers: {
        "Authorization": `Bearer ${TOKEN}`
      },
      params: {
        "methodId": this.methodId,
        "startAt": startAt,
        "endAt": endAt
      }
    })
    .then(function (response) {
      const data = response.data;
      const firstData = data[0];
      const times = firstData.map((d) => moment(d.time).format('kk:mm'));

      var yLabelCells = '';
      for (var i = 0; i < times.length; i++) {
        yLabelCells += `<div class="calendar-cell">` + times[i] + "</div>";
      }
      var colums = ''
      for (var i = 0; i < data.length; i++) {
        const dataPerDay = data[i];
        const targetDate = moment(dataPerDay[0].time);
        const dateLabel = mediaQuery.matches ? targetDate.format('M/D(ddd)') : targetDate.format('M/D') + '<br>' + targetDate.format('(ddd)');
        const isSaturday = targetDate.day() === 6
        const saturdayClass = isSaturday ? 'saturday' : ''
        const isSunday = targetDate.day() === 0
        const sundayClass = isSunday ? 'sunday' : ''
        var column = `<div class="calendar-column"><div class="calendar-cell calendar-header ${saturdayClass} ${sundayClass}">${dateLabel}</div>`
        for (var index = 0; index < dataPerDay.length; index++) {
          const dataPerTime = dataPerDay[index];
          const canReserve = dataPerTime.canReserve;
          const time = dataPerTime.time;
          if (canReserve) {
            column += `<a data-action="calendar#selectTime" data-time="${time}" class="calendar-cell can-reserve"><i class="far fa-circle"></i></a>`
          } else {
            column += `<div class="calendar-cell cannot-reserve">×</div>`
          }

        }
        column += `</div>`
        colums += column;
      }
      calendarView.classList.remove('disabled');
      calendarView.innerHTML = `
        <div class="calendar-column">
          <div class="calendar-cell calendar-header"></div>
          ${yLabelCells}
        </div>
        ${colums}
      `
    })
    .catch(function (reason) {
      calendarView.innerHTML = `
        <div class="calendar-loading">
          ※ 現在予約を受け付けていません。
        </div>
      `;
    })
  }

  prevWeek() {
    if (this.targetDate.isSame(moment(), 'day')) {
      return false;
    }
    this.targetDate = this.targetDate.clone().add(-7, 'days');
    this.renderCalendarView(this.targetDate);
    this.updatePrevWeekButton(this.targetDate);
  }

  nextWeek() {
    this.targetDate = this.targetDate.clone().add(7, 'days');
    this.renderCalendarView(this.targetDate);
    this.updatePrevWeekButton(this.targetDate);
  }

  selectTime(event) {
    this.interviewStartTime = moment(Number(event.currentTarget.dataset.time));
    this.interviewEndTime = this.interviewStartTime.clone().add(INTERVIEW_MINUTES, 'minutes');
    const method = METHODS.find((method) => method.id === this.methodId);
    this.interviewMethodTarget.textContent = method.name;
    this.interviewDateTarget.textContent = `${this.interviewStartTime.format('YYYY年M月D日(ddd) kk:mm')}〜${this.interviewEndTime.format('kk:mm')}`
    this.modalTarget.classList.add('is-active');
    $('#ch-plugin').addClass('is-hidden');
    if (!this.defaultModal) {
      this.defaultModal = this.modalTarget.innerHTML;
    }
  }

  closeModal() {
    this.modalTarget.classList.remove('is-active')
    $('#ch-plugin').removeClass('is-hidden');
  }

  validate() {
    const emailRegex = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
    // バリデーションメッセが既にある場合は削除しておく
    this.helpTargets.forEach((target, index) => {
      // 郵便番号の対応可否はここではチェックしないため削除しない
      if (target.dataset.helpType != 'zipcode') {
        target.remove();
      }
    })
    // バリデーションチェック
    this.formTextTargets.forEach((target, index) => {
      // 必須チェック
      if (target.dataset.inputRequired && !target.value) {
        target.parentNode.insertAdjacentHTML('beforeend', `<p data-calendar-target="help" class='help is-danger'>必須項目です</p>`)
      // emailチェック
      } else if (target.dataset.inputName === 'email' && !emailRegex.test(target.value)) {
        target.parentNode.insertAdjacentHTML('beforeend', `<p data-calendar-target="help" class='help is-danger'>メールアドレスを入力してください</p>`)
      }
    });
  }

  valueToConfirm() {
    // text input
    this.formTextTargets.forEach((target, index) => {
      // stateに値を保存
      this[target.dataset.inputName] = target.value;
      // class名をテキスト表示版に変更
      target.parentNode.className = target.dataset.inputName !== 'freeText' ? 'calendar-modal-body-text' : 'calendar-modal-body-text-area';
      target.after(target.value);
      target.classList.add('is-hidden');
    });

    // checkbox
    this.formCheckBoxTargets.forEach((target, index) => {
      target.className = 'calendar-modal-body-text';
      // 連絡方法
      if (target.dataset.inputName === 'contactMethod') {
        const contactMethods = this.contactMethodTargets.map((t) => t.checked ? CONTACT_METHODS[t.value - 1] : null).filter(v => v);
        this.contactMethodIds = this.contactMethodTargets.map((t) => t.checked ? Number(t.value) : null).filter(v => v);;
        target.innerHTML = contactMethods.join('、');
      // どうやって知ったか
      } else if (target.dataset.inputName === 'media') {
        const media = this.mediaTargets.map((t) => t.checked ? MEDIA[t.value - 1] : null).filter(v => v);
        this.mediaIds = this.mediaTargets.map((t) => t.checked ? Number(t.value) : null).filter(v => v)
        target.innerHTML = media.join('、');
      }
    });

    // select
    this.formSelectTargets.forEach((target, index) => {
      target.className = 'calendar-modal-body-text';
      const text = CONSULTATION_CONTENT[this.consultationContentTarget.value - 1];
      this.consultationContent = this.consultationContentTarget.value;
      target.innerHTML = text;
    });
  }

  confirm(event) {
    event.currentTarget.disabled = true;
    this.validate();
    // エラーがある場合はキャンセル
    if (this.helpTargets.length > 0) {
      event.currentTarget.disabled = false;
      return;
    }

    this.valueToConfirm();

    // フォーム上のSTEPの画像をtoggle
    this.stepImageTargets.forEach((target, index) => {
      target.classList.toggle('is-hidden')
    });
    this.backButtonTarget.classList.remove('is-hidden');
    this.sendButtonTarget.classList.remove('is-hidden');
    this.confirmButtonTarget.classList.add('is-hidden');
  }

  backToForm() {
    this.modalTarget.innerHTML = this.defaultModal;
    this.setFormValues();
  }

  createPostJson() {
    var selected_contact_methods_attributes = {}
    this.contactMethodIds.forEach((methodId, index) => {
      selected_contact_methods_attributes[index] = {
        "contact_method_id": methodId
      }
    });
    var selected_media_attributes = {}
    this.mediaIds.forEach((mediumId, index) => {
      selected_media_attributes[index] = {
        "medium_id": mediumId
      }
    });
    const postJson = {
      "form": {
        "name": this.name,
        "kana": this.kana,
        "tel_no": this.telNo,
        "email": this.email,
        "zipcode": !this.zipcode ? null : this.zipcode.replace('-', ''),
        "address": this.address1 + this.address2,
        "consultation_method_id": this.methodId,
        "consultation_content_id": this.consultationContent,
        "free_text": this.freeText,
        "start_at": this.interviewStartTime.unix(),
        "end_at": this.interviewEndTime.unix(),
        "selected_contact_methods_attributes": selected_contact_methods_attributes,
        "selected_media_attributes": selected_media_attributes
      }
    }
    return postJson;
  }

  sendForm() {
    const sendButton = this.sendButtonTarget;
    const backButton = this.backButtonTarget;

    sendButton.disabled = true;
    sendButton.textContent = '送信中';
    backButton.disabled = true;

    const postJson = this.createPostJson();

    // create schedule APIを叩く
    axios.post(API_URL + '/api/v1/shops/schedules', postJson, {
      headers: {
        "Authorization": `Bearer ${TOKEN}`
      },
    })
    .then(function (response) {
      location.href = '/complete.html';
    })
    .catch(function (reason) {
      sendButton.disabled = false;
      sendButton.textContent = '送信する';
      backButton.disabled = false;
      alert('予約ができませんでした。他の日程を選択してください。');
    });
  }

  toggle(event) {
    if (event.detail) {
      // 郵便番号のフォームに値を入力
      this.zipcode = event.detail;
      const zipcodeTarget = this.formTextTargets.find((target) => target.dataset.inputName === 'zipcode');
      zipcodeTarget.value = this.zipcode;
      this.searchAddressByZipcode();
      this.element.classList.remove('is-hidden');
    }
  }

  // 郵便番号から住所を検索する
  searchAddressByZipcode(event) {
    const zipcodeTarget = this.formTextTargets.find((target) => target.dataset.inputName === 'zipcode');
    const address1Target = this.formTextTargets.find((target) => target.dataset.inputName === 'address1');
    const helpTarget = this.helpTargets.find((target) => target.dataset.helpType === 'zipcode');
    const code = zipcodeTarget.value.replace(/[^0-9]/g, '');
    if (helpTarget) {
      helpTarget.remove();
    }

    if (code.length < 7) {
      return;
    }

    this.checkZipcode(code, zipcodeTarget, address1Target, helpTarget);
  }

  checkZipcode(code, zipcodeTarget, address1Target, helpTarget) {
    axios.get(API_URL + '/api/v1/shops/check_zipcode', {
      headers: {
        "Authorization": `Bearer ${TOKEN}`
      },
      params: {
        "code": code,
      }
    })
    .then(function (response) {
      if (response.data.length > 0) {
        axios.get('https://api.zipaddress.net', {
          params: {
            "zipcode": code
          }
        })
        .then(function (response) {
          const data = response.data.data;
          address1Target.value = data.pref + data.address;
        });
      } else {
        // 対応不可な郵便番号の場合はアラートを出す
        zipcodeTarget.parentNode.insertAdjacentHTML('beforeend', `<p data-calendar-target="help" data-help-type="zipcode" class='help is-danger'>入力した郵便番号は訪問の対象外です</p>`);
      }
    });
  }
}
